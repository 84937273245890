export class EnvService {
    // API url
    public apiUrl = 'https://192.168.0.153/apiz'; // default value if env.js is not loaded
    public portalUrl = 'http://fakeaddress.it';
    public gridVisible = false;
    public mapVisible = true;
    public facebookVisible=true;
    public googleVisible=true;
    public spidVisible=false;
    public viewAmbiti = 'vw_AmbitoSegnalazioni';
    public tableSegnalazione = 'TSK_Segnalazione';
    constructor() {
      // console.log("SERVICE ENVIRONMENT STARTING...");
    }


    getViewAmbiti()
    {
      return this.viewAmbiti;
    }

    getTableSegnalazione()
    {
      return this.tableSegnalazione;
    }



    getPortalUrl()
    {
      return this.portalUrl;
    }

    getUrl()
    {
      return this.apiUrl;
    }

    getGridVisibility() {
      return this.gridVisible;
    }

    getMapVisibility() {
      return this.mapVisible;
    }
    getFacebookVisibility() {
      return this.facebookVisible;
    }

    getGoogleVisibility() {
      return this.googleVisible;
    }

    getSpidVisibility() {
      return this.spidVisible;
    }


  }
