<div class="it-hero-wrapper" style="min-height: 380px;">
  <div class="img-responsive-wrapper">
     <div class="img-responsive">
        <div class="img-wrapper"><img  src="/assets/home.jpg" title="" alt="banner"></div>
     </div>
  </div>
</div>

<div class="container-accedi" *ngIf="accedi">
  <div class="container">
<div class="row">
  <div class="col-12 col-lg-12">
    <!--start card-->

    <div class="card-wrapper">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title" style="color:rgb(158, 26, 26)">Segnala un problema!</h2>
          <p class="card-text" style="font-family: 'Titillium Web';font-size: 20px;">Attraverso la piattaforma i cittadini posso formulare suggerimenti,segnalazioni e reclami su mappa per il miglioramento della qualità dei servizi offerti e la visibilità del nucleo abitato.</p>
        </div>
      </div>
    </div>
    <!--end card-->
  </div>
</div>


<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card-wrapper card-space"> <!-- Fare ngIf !user-->
      <div class="card card-bg">
        <div class="card-body">
          <div class="etichetta">
            <svg class="icon" role="img" aria-label="Servizio">
              <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-settings"></use>
            </svg>
            <span>Servizio</span>
          </div>
          <h4 class="card-title">Nuova segnalazione</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <a class="read-more" >
            <span [routerLink]="'/new-segnalazione'" style="cursor: pointer;" class="text" tabindex="-1">Vai alla pagina</span>
            <svg class="icon" role="img"  aria-label="Nuova segnalazione">
              <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-arrow-right"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card-wrapper card-space">
      <div class="card card-bg">
        <div class="card-body">
          <div class="etichetta">
            <svg class="icon" role="img" aria-label="Servizio">
              <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-settings"></use>
            </svg>
            <span>Servizio</span>
          </div>
          <h4 class="card-title">Le tue segnalazioni</h4>
          <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <a class="read-more" >
            <span  [routerLink]="'/tue-segnalazione'" style="cursor: pointer;" class="text" tabindex="-1">Vai alla pagina</span>
            <svg class="icon" role="img" aria-label="Vai alle tue segnalazione">
              <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-arrow-right"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</div>



