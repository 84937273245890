<ngx-spinner>
    <p style="color: white"> Acquisto in corso... </p>
</ngx-spinner>
<div class="container">
    <div class="row mt-5">
        <div class="card card-teaser rounded shadow">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <h3 style="color:green">Segnalazione completata con successo <i class="fas fa-check"></i></h3>
                    </div>


                    <!--RIEPILOGO DATI-->
                    <div class="col-12 mt-5" style="text-align:left ;">
                        <h4>Riepilogo dei dati inseriti</h4>
                        <div class="row">
                            <div class="col-sm-12">
                                <div><b>Descrizione Segnalazione:</b> {{ segnalazioneSelected.Description }}</div> <br>
                                <div><b>Categoria:</b> {{ segnalazioneSelected.Code }}</div>
                                <div><b>Data:</b> {{ segnalazioneSelected.DataSegnalazione | date: 'dd/MM/yyyy'}}</div>
                                <div><b>Email:</b> {{ segnalazioneSelected.CreatedBy }}</div>
                                <div><b>Indirizzo:</b> {{ segnalazioneSelected.Indirizzo }}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row mt-5 mb-5">
        <div class="col-6">
            <div class="container">
                <button type="submit" type="button" class="btn btn-primary btn-lg" (click)="navigateToHome()" ><i class="fas fa-arrow-left"></i>&nbsp;Torna alla Home</button>

            </div>
        </div>
        <div class="col-6">
            <div class="container text-right">
                <button type="submit" type="button" class="btn btn-success btn-lg" (click)="navigateToNewSegnalazione()"><i class="fas fa-plus"></i>&nbsp;Crea
                    nuova segnalazione</button>
            </div>
        </div>
    </div>
</div>