<div class="it-header-wrapper">
  <div class="it-header-slim-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="it-header-slim-wrapper-content">
            <a class=" navbar-brand" [href]="parametri?.linkOwner" target="_blank">{{parametri?.owner}}
            </a>

            <div class="header-slim-right-zone">
              <div class="it-access-top-wrapper" *ngIf="!user">
                <button class="btn btn-primary btn-sm" href="#" type="button" (click)="login()">Accedi</button>
              </div>
              <div class="dropdown" *ngIf="user">
                <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="avatar size-sm" style="margin-right:10px;">
                    <img src="../assets/default-avatar.jpg" alt="Gioacchino Romani">
                  </div>
                  <a>{{user?.name}}</a>
                  <svg class="icon" role='img'>
                    <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-expand"></use>
                  </svg>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <div class="link-list-wrapper">
                    <ul class="link-list">
                      <li><a class="list-item" (click)="logout()" style="cursor: pointer;"><span>Disconnetti</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="it-nav-wrapper">
    <div class="it-header-center-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="it-header-center-content-wrapper">
              <div class="it-brand-wrapper">
                <a href="#">
                  <svg class="icon" role='img'>
                    <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-code-circle"></use>
                  </svg>
                  <div class="it-brand-text">
                    <h2 class="no_toc">{{parametri?.titoloHeader}}</h2>
                    <h3 class="no_toc d-none d-md-block">{{parametri?.titoloHeader}}</h3>
                  </div>
                </a>
              </div>
              <div class="it-right-zone" [innerHtml]="parametri?.header | noSanitize">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="it-header-navbar-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg has-megamenu">
              <button class="custom-navbar-toggler" type="button" aria-controls="nav10" aria-expanded="false"
                aria-label="Toggle navigation" data-target="#nav10">
                <svg class="icon" role='img'>
                  <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-burger"></use>
                </svg>
              </button>
              <div class="navbar-collapsable" id="nav10">
                <div class="overlay"></div>
                <div class="close-div sr-only">
                  <button class="btn close-menu" type="button"><span class="it-close"></span>close</button>
                </div>
                <div class="menu-wrapper">
                  <ul class="navbar-nav">
                    <li class="nav-item" routerLinkActive="active"><a class="nav-link"
                        [routerLink]="['/home']"><span>Segnalazioni
                        </span><span class="sr-only"></span></a></li>
                    <li *ngIf="parametri?.sitoRiferimento" class="nav-item" routerLinkActive="active"><a
                        class="nav-link" [routerLink]="[parametri?.sitoRiferimento]"><span>Torna al sito
                          istituzionale</span></a></li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<main>
  <router-outlet></router-outlet>
</main>
<footer class="it-footer">
  <div class="it-footer-main">
    <div class="container">
      <section class="py-4 ">
        <div class="row">
          <div *ngIf="parametri?.footer1" class="col-lg-4 col-md-4 pb-2" [innerHtml]="parametri?.footer1 | noSanitize">
          </div>
          <div *ngIf="parametri?.footer2" class="col-lg-4 col-md-4 pb-2" [innerHtml]="parametri?.footer2 | noSanitize">
          </div>
          <div *ngIf="parametri?.footer3" class="col-lg-4 col-md-4 pb-2" [innerHTML]="parametri?.footer3 | noSanitize">
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="it-footer-small-prints clearfix">
    <div class="container">
      <h3 class="sr-only">Sezione Link Utili</h3>
      <ul class="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
        <li class="list-inline-item"><a href="/accessibilita" title="Accessibilità">Accessibilità</a></li>
        <li class="list-inline-item"><a href="#" title="Note Legali">Note legali</a></li>
        <li class="list-inline-item"><a href="#" title="Privacy">Privacy policy</a></li>
        <li class="list-inline-item"><a href="#" title="Cookie Policy">Cookie policy</a> </li>
      </ul>
    </div>
  </div>
</footer>
<a role="button" aria-hidden="true" data-attribute="back-to-top" class="back-to-top cur"
  aria-label="torna all'inizio dei contenuti">
  <svg class="icon icon-light" role='img'>
    <use href="/assets/bootstrap-italia/svg/sprite.svg#it-collapse"></use>
  </svg>
</a>
<div class="cookiebar">
  <p>Questo sito utilizza cookie tecnici, analytics e di terze parti. <br />Proseguendo nella navigazione accetti
    l’utilizzo dei cookie.</p>
  <div class="cookiebar-buttons">
    <a href="#" class="cookiebar-btn">Politica sui cookie<span class="sr-only">cookies</span></a>
    <button data-accept="cookiebar" class="cookiebar-btn cookiebar-confirm">Accetto<span class="sr-only"> i
        cookies</span></button>
  </div>
</div>