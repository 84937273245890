<div class="container" *ngIf="spinner">
  <div class="row">
    <div class="col-4">
    </div>
    <div class="col-4" style="text-align: center;margin-top: 10px;">
      <div class="cover-progress d-flex align-items-center justify-content-center">
        <div class="progress-spinner progress-spinner-active">
          <span class="sr-only">Caricamento...</span>
        </div>
      </div>
    </div>
    <div class="col-4">
    </div>
  </div>
</div>


<div class="it-hero-wrapper" *ngIf="sourceLoaded && mapVisible" style="min-height: 320px;">
  <div class="img-responsive-wrapper">
    <div class="img-responsive">
      <!-- <app-mappa [mapPoint]="mapPoint" ></app-mappa> <br> -->
      <div #mapWrapper class="mapWrapper">
        <div id="map" leaflet #map [leafletOptions]="MapOptions" (leafletMapReady)="initializeMap($event)"
          [leafletMarkerCluster]="markerClusterData" [leafletMarkerClusterOptions]="markerClusterOptions"
          (leafletMarkerClusterReady)="markerClusterReady($event)"></div>
      </div>
    </div>
  </div>
</div>

<!--TABELLA FILTRAGGIO-->
<div class="container-fluid" style="background-color: #f3f3f3;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <h4 style="margin-bottom: 0px;margin-top:10px; font-weight: 100;">Filtra i risultati</h4>
        <div class="row">
          <div class="col-lg-4 col-md-12" style="margin-top:30px">
            <label class="active">Categoria</label>
            <div class="form-group">
              <ng-select style="background-color: white;" [groupBy]="groupByFn" [(ngModel)]="ambito">
                <ng-option *ngFor="let settore of settori" [value]="settore">
                  {{settore.Description}}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-4 col-md-12" style="margin-top:30px">
            <label class="active">Testo:</label>
            <div class="form-group">
              <input [(ngModel)]="testo" type="text" class="form-control" id="formGroupExampleInput2">
            </div>
          </div>
          <div class="col-lg-4 col-md-12" style="margin-top:30px">
            <label class="active">Stato:</label>
            <div class="form-group">
              <ng-select style="background-color: white;" [(ngModel)]="stato">
                <ng-option value="CONCLUSA">Conclusa</ng-option>
                <ng-option value="LAVORAZIONE">Lavorazione</ng-option>
                <ng-option value="INSERITA">Inserita</ng-option>
                <ng-option value="RIFIUTATA">Rifiutata</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <label class="active">Dal:</label>
            <div class="form-group">
              <input [(ngModel)]="startDate" style="font-weight: 100;" type="date" class="form-control"
                id="formGroupExampleInput2">
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <label data-toggle="tooltip" title="Per Davide" class="active">Al:</label>
            <div class="form-group">
              <input [(ngModel)]="endDate" style="font-weight: 100;" type="date" class="form-control"
                id="formGroupExampleInput2">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-lg-3">
            <button type="button" class="btn btn-secondary btn-block" (click)="azzera()">Azzera filtri</button>
          </div>
          <div class="col-4  col-lg-3">
            <button type="button" class="btn btn-primary  btn-block" (click)="filtraggio()">Filtra</button>
          </div>
        </div><br />
      </div>
    </div>
  </div>
  <i class="fa-solid fa-user"></i>
</div>





<!--TABELLA SEGNALAZIONI-->
<div class="container" *ngIf="!spinner && gridVisible" style="margin-top:50px">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Data</th>
        <th scope="col" style="cursor: pointer;" sortable="_Ambito_Description" (sort)="onSort($event)">Categoria
        </th>
        <th class="d-none d-lg-table-cell" scope="col">Testo</th>
        <th scope="col">Indirizzo</th>

        <th scope="col">Dettagli</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let segnalazione of segnalazioniFiltered">
        <td>{{segnalazione.DataSegnalazione | date:'dd/MM/yyyy'}}<br>
          <span style="background-color: red;" ngbTooltip="{{segnalazione.tooltip}}"><i
              class="{{segnalazione.icona}}"></i>
            {{segnalazione.stato}}</span>
        </td>
        <td>{{ segnalazione._Ambito_Description }}</td>
        <td class="d-none d-lg-table-cell">{{ segnalazione.Description }}</td>
        <td> {{segnalazione.Indirizzo}}</td>

        <td>
          <button data-toggle="modal" data-target="#exampleModal" type="button" (click)="setDetail(segnalazione)"
            class="btn ">
            <svg class="icon icon-sm" aria-hidden="true">
              <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-info-circle"></use>
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!--CARD SEGNALAZIONE-->

<div class="container" *ngIf="!spinner && !gridVisible" style="margin-top: 25px;">
  <div class="col-12" *ngFor="let segnalazione of segnalazioniFiltered">
    <div class="card-wrapper card-space">
      <div class="card card-bg">
        <div class="card-body">
          <div class="row" style="align-items: center;">
            <div class="col-12">
              <h3>
                <span ngbTooltip="{{segnalazione.tooltip}}" class="{{segnalazione.color}}">
                  <i class="{{segnalazione.icona}}"></i>&nbsp;{{segnalazione.stato}}</span>
                {{ segnalazione._Ambito_Code }}
                <svg class="icon icon-sm" aria-hidden="true">
                  <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-arrow-right-triangle"></use>
                </svg>
                {{segnalazione._Categoria_Description}}
              </h3>
            </div>
            <!-- <div class="col-2" >
              <h4 style="float: right;"><span ngbTooltip="Riferimento segnalazione" class="badge badge-primary">#{{segnalazione.Id}}</span></h4>
            </div> -->
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-12">
              <small style="font-size: 12px;">
                <svg class="icon icon-sm" aria-hidden="true">
                  <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-clock"></use>
                </svg> &nbsp; Pubblicata il {{segnalazione.DataSegnalazione | date:'dd/MM/yyyy'}}
              </small>
            </div>
            <div class="col-lg-3 co-sm-12">
              <small style="font-size: 12px;">
                <svg class="icon icon-sm" aria-hidden="true">
                  <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-clock"></use>
                </svg> &nbsp; Ultima modifica del {{segnalazione.BeginDate | date:'dd/MM/yyyy'}}
              </small>
            </div>
          </div>
          <div class="row" style="margin-top: 10px;">
            <div class="col-lg-4 col-sm-12">
              <!-- <img style="width: 100%;" src="https://i0.wp.com/port2flavors.com/wp-content/uploads/2022/07/placeholder-614.png?w=1200&ssl=1"> -->
              <img style="width: 100%;" [src]=" apiUrl + '/class/downloadAllegatoByIdObj/' + segnalazione.Id" />
            </div>
            <div class="col-lg-8 col-sm-12">
              <small style="font-size: 22px;">
                <svg class="icon icon-sm" aria-hidden="true">
                  <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-map-marker-circle"></use>
                </svg> &nbsp;{{segnalazione.Indirizzo}}
              </small><br>
              <div style="margin-right: 5px;">
                <h6>{{ segnalazione.Description }} </h6>
              </div>
              <div style="position: absolute;bottom: -50px;right: 0;">
                <button data-toggle="modal" data-target="#exampleModal" type="button" (click)="setDetail(segnalazione)"
                  class="btn btn-outline-primary">
                  <svg class="icon icon-sm" aria-hidden="true">
                    <use xlink:href="/assets/bootstrap-italia/svg/sprite.svg#it-info-circle"></use>
                  </svg>&nbsp;Dettagli
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="container">
  <button type="button" routerLink="/new-segnalazione" style="margin-top: 50px;margin-bottom: 20px;"
    class="btn btn-primary">Clicca qui per inserire una nuova segnalazione</button>
</div>


<!-- MODALE DETTAGLIO -->
<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="exampleModal"
  aria-labelledby="exampleModalTitle" *ngIf="detail">
  <div class="modal-dialog modal-xl modal-sm modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalTitle">Dettaglio segnalazione</h4>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <label class="active">Categoria:</label>
            <input class="form-control" id="disabledInput" type="text" placeholder="{{detail._Ambito_Description}}"
              aria-label="Label" disabled>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <label class="active">Gruppo:</label>
            <input class="form-control" id="disabledInput" type="text" placeholder="{{detail._Ambito_Code}}"
              aria-label="Label" disabled>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <label class="active">Data:</label>
            <input class="form-control" id="disabledInput" type="text"
              placeholder="{{detail.DataSegnalazione | date:'dd/MM/yyyy' }}" aria-label="Label" disabled>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <label class="active">Stato:</label>
            <input class="form-control" id="disabledInput" type="text" placeholder="{{detail.stato}}" aria-label="Label"
              disabled>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <label class="active">Posizione:</label>
            <div class="form-group" style="min-height: 380px;">
              <app-mappa [viewIndirizzo]="false" [mapPoint]="mapPoint"></app-mappa> <br>
              <input class="form-control" id="disabledInput" type="text" placeholder="{{detail.Indirizzo}}"
                aria-label="Label" disabled>
            </div>
          </div>
        </div>

        <div class="row mt-5" >
          <div class="col-12">
            <img width="100%" alt="Nessun immagine" [src]=" apiUrl + '/class/downloadAllegatoByIdObj/' + detail.Id"  />
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-primary btn-sm" data-dismiss="modal" type="button">Chiudi</button>
        </div>
      </div>
    </div>
  </div>