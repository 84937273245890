import { NgbdCarouselBasic } from './pages/test/carousel-basic';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { MappaComponent } from './pages/mappa/mappa.component';
import { AuthGuard } from './auth-guard.service';
import { HomeComponent } from './pages/home/home.component'

import { ScriptComponent } from './pages/test/script/script.component';

import { NewSegnalazioneComponent } from './pages/new-segnalazione/new-segnalazione.component';
import { TueSegnalazioneComponent } from './pages/tue-segnalazione/tue-segnalazione.component';
import { AccessibilitaComponent } from './pages/accessibilita/accessibilita.component';
import { RiepilogoSegnalazioneComponent } from './pages/riepilogo-segnalazione/riepilogo-segnalazione.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent
  },
  { path: 'new-segnalazione', canActivate:[AuthGuard], component: NewSegnalazioneComponent },
  { path: 'tue-segnalazione',  canActivate:[AuthGuard], component: TueSegnalazioneComponent },
  { path: 'riepilogo-segnalazione',  canActivate:[AuthGuard], component: RiepilogoSegnalazioneComponent },
  { path: 'prova', component: ScriptComponent },
  { path: 'mappa', component: MappaComponent },
  { path: 'test', component: NgbdCarouselBasic },
  { path:'accessibilita',component: AccessibilitaComponent},
  { path: 'auth', loadChildren: () => import('src/app/auth/auth.module').then(m => m.NgxAuthModule) },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }



