import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-riepilogo-segnalazione',
  templateUrl: './riepilogo-segnalazione.component.html',
  styleUrls: ['./riepilogo-segnalazione.component.css']
})
export class RiepilogoSegnalazioneComponent implements OnInit {


  segnalazioneSelected: any = history.state.segnalazione;

  constructor(private router: Router) { }

  ngOnInit(): void {
    console.log(this.segnalazioneSelected);
  }

  navigateToHome()
  {
    console.log("ciao")
    this.router.navigate(['/home']);
  } 
  navigateToNewSegnalazione()
  {
    this.router.navigate(['/new-segnalazione']);
  }

}
