import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { HttpService } from 'src/app/core/services/api.service';
import { EnvService } from 'src/app/core/services/env.service';
import { locationService } from 'src/app/core/services/location.service';
import { UserStore } from 'src/app/core/services/user.store';

@Component({
  selector: 'app-new-segnalazione',
  templateUrl: './new-segnalazione.component.html',
  styleUrls: ['./new-segnalazione.component.css']
})
export class NewSegnalazioneComponent implements OnInit {


  mapPoint: any;
  segnalazioneForm: FormGroup;
  body: any;
  isOk: boolean = false;
  listaFile: any = [];
  myLat: any;
  myLong: any;
  settori: any;
  indirizzo: any;
  allegati: any = [];
  user: any;
  viewAmbiti: any;
  parametri: any;
  tableSegnalazione: any;

  constructor(protected cd: ChangeDetectorRef, private location: locationService, private userStore: UserStore, private classService: HttpService,
    protected httpClient: HttpClient, private envService: EnvService, private spinner: NgxSpinnerService, private router: Router) {

    this.viewAmbiti = this.envService.getViewAmbiti();
    this.tableSegnalazione = this.envService.getTableSegnalazione();

    this.httpClient.get('assets/siteConfig.json').subscribe((res: any) => {
      this.parametri = res;

    });

    this.location.getPosition().then((pos: any) => {

      this.mapPoint = {
        name: null,
        latitude: pos.Lat,
        longitude: pos.Long,
        createMarker: true
      }

    });


    this.segnalazioneForm = new FormGroup({
      categoria: new FormControl('', [Validators.required]),
      testo: new FormControl('', [Validators.required]),
      luogo: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.user = this.userStore.getUser();
    console.log(this.user)
    this.classService.getCard(this.viewAmbiti).subscribe((res: any) => {
      this.settori = res.data.list;
    });
  }


  get testo() { return this.segnalazioneForm.get('testo')!; }
  get categoria() { return this.segnalazioneForm.get('categoria')!; }
  get luogo() { return this.segnalazioneForm.get('luogo')!; }

  formSubmit() {
    this.spinner.show();
    var oggi = new Date();
    this.body = {
      Description: this.testo.value,
      Code: this.categoria.value.Code,
      Ambito: this.categoria.value.Id,
      Indirizzo: this.indirizzo.name,
      latitude: new Number(this.indirizzo.latitude),
      longitude: new Number(this.indirizzo.longitude),
      CreatedBy: this.user.email,
      DataSegnalazione: oggi,
      Checklist: this.categoria.value.Checklist,
      Asset: this.categoria.value.Asset,
      TipoTemplate: 2

    }
    console.log(this.body)
    this.classService.post("class/" + this.tableSegnalazione + "/cards", this.body).subscribe((res: any) => {
      if (res.status == 'success') {
        this.spinner.hide();

        let req = []
        let reqAttach = [];

        if (this.allegati.length > 0) {
          this.allegati.forEach(allegato => {
            req.push(this.classService.addClassAllegato(res.data.Id, 0, "FREE", "Allegato segnalazione", allegato));
          });

          forkJoin(req).subscribe((res2: any) => {
            console.log(res2);
            res2.forEach(response => {
              reqAttach.push(this.classService.updateDettagliScheda("Attachments", {
                IdFile: response.body.data.idFile,
                Description: "Allegato alla segnalazione",
                FileDescription: "",
                IdCard: res.data.Id,
                ExpireDate: null,
                CategoryCode: "FREE",
                DataRiferimento: null
              }));
            });

            forkJoin(reqAttach).subscribe(() => {
              this.navigateToRiepilogo();
            });
          });
        } else {
          this.navigateToRiepilogo();
        }

      }



    });


  }
  navigateToRiepilogo() {
    let navigationExtras: NavigationExtras = {
      state: { segnalazione: this.body }
    };
    this.router.navigate(['/riepilogo-segnalazione'], navigationExtras);
  }

  formInvalid() {
    return this.segnalazioneForm.invalid
  }
  checkValue() {
    this.isOk = !this.isOk;
  }

  removeFile(x) {
    const index = this.allegati.indexOf(x);
    if (index > -1) {
      this.allegati.splice(index, 1);

    }
  }

  onFileChange(e) {
    var file = e.srcElement.files[0];
    console.log(file);
    var value: any;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      value = {
        File: {
          IdFile: -1,
          Name: file.name,
          Size: file.size,
          FilePicked: { content: reader.result },
          Base64: reader.result
        }
      };
      this.allegati.push(value)
    }
  }
  setAddress(e) {
    this.segnalazioneForm.get('luogo').setValue(e.name)
    // if (e.address) {
    //   Object.keys(e.address).forEach((key) => {
    //     console.log(e.address[key]);
    //   });
    // }
    console.log(e)
    this.indirizzo = e;
    this.cd.markForCheck();
  }

  groupByFn(item) {
    return item.Gruppo
  }

}
