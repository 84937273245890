import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Directive, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { CRS, latLng, Map, MapOptions, tileLayer } from 'leaflet';
import 'leaflet.markercluster';
import { HttpService } from 'src/app/core/services/api.service';
import { locationService } from 'src/app/core/services/location.service';
import { UserStore } from 'src/app/core/services/user.store';
import { MappaComponent } from '../mappa/mappa.component';
import { EnvService } from './../../core/services/env.service';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: any;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: any = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();



  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-tue-segnalazione',
  templateUrl: './tue-segnalazione.component.html',
  styleUrls: ['./tue-segnalazione.component.scss']
})
export class TueSegnalazioneComponent {
  gridVisible: boolean = true
  spinner: boolean = true;
  user: any;
  segnalazioniFiltered: any;
  settori: any;
  testo: any;
  mapPoint: any;
  ambito: any;
  stato: any;
  startDate: any;
  endDate: any;
  body: any;
  filter = []
  tooltip;
  icone: {};
  map: any;
  detail: any;
  segnalazioniOriginali: any;
  viewDetail: boolean;
  MapOptions: MapOptions = {};
  @ViewChild('mapWrapper', { static: true }) mapWrapper: ElementRef;
  @ViewChild(MappaComponent) mappa: MappaComponent;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  mapVisible: any = false;
  sourceLoaded: boolean = false;
  options: any = null;
  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData: L.Marker[] = [];
  markerClusterOptions: L.MarkerClusterGroupOptions;
  viewAmbiti: any;
  tableSegnalazione: any;
  apiUrl: string;
  constructor(
    protected cd: ChangeDetectorRef,
    protected router: Router,
    public datepipe: DatePipe,
    private service: HttpService,
    private userStore: UserStore,
    private location: locationService,
    private env: EnvService
  ) {
    this.apiUrl = env.apiUrl;
  }

  ngOnInit(): void {
    this.mapVisible = this.env.getMapVisibility();
    this.gridVisible = this.env.getGridVisibility();
    this.viewAmbiti = this.env.getViewAmbiti();
    this.tableSegnalazione = this.env.getTableSegnalazione();
    this.user = this.userStore.getUser();
    this.filter = [["CreatedBy", "=", this.user.email]]


    //RICHIAMO LE SEGNALAZIONI
    var store = this.service.getDataSourceDevExp(this.tableSegnalazione, this.filter, false, null, null, true);
    store.on("loading", this.loading.bind(this));
    store.on("loaded", this.loaded.bind(this));
    store.load();

    this.uploadAmbiti();
    this.uploadPosition();

  }


  uploadAmbiti() {
    this.service.getCard(this.viewAmbiti).subscribe((res: any) => {
      this.settori = res.data.list;
    });
  }

  uploadPosition() {
    this.location.getPosition().then((pos: any) => {
      this.mapPoint = {
        name: null,
        latitude: pos.Lat,
        longitude: pos.Long,
        createMarker: true
      }
      this.MapOptions = {
        zoom: 8,
        layers: [
          tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'OSM' })
        ],
        crs: CRS.EPSG3857,
        center: latLng(pos.Lat, pos.Long),
      };
    });
  }
  markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
    console.log(this.markerClusterGroup)
  }

  setMapPoint() {
    this.markerClusterData = this.generateMarker(this.segnalazioniFiltered)
  }

  initializeMap(map: Map) {
    L.control.fullscreen({
      position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
      title: 'Visualizza schermo intero', // change the title of the button, default Full Screen
      titleCancel: 'Esci da schermo intero', // change the title of the button when fullscreen is on, default Exit Full Screen
    }).addTo(map);
    this.map = map;

  }

  generateMarker(coords: any): L.Marker[] {
    const data: L.Marker[] = [];
    const icon = L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      // specify the path here
      iconUrl: "assets/marker-icon.png",
      shadowUrl: "assets/marker-shadow.png"
    });

    for (let i = 0; i < coords.length; i++) {
      if (coords[i]['latitude'] && coords[i]['longitude']) {
        let marker = L.marker([coords[i]['latitude'], coords[i]['longitude']], { icon });
        marker.bindPopup('<div style="text-align:center;" ><span class="badge badge-secondary"><i class="' + coords[i]['icona'] + '"></i>&nbsp;' + coords[i]['stato'] + '</span></div><div style="margin-top:1px;"><strong>' + coords[i]['_Categoria_Gruppo'] +
          '</strong>- ' + coords[i]['_Categoria_Description'] + '</div><br> Pubblicata il <strong>' + formatDate(coords[i]['DataInizio'], "dd/MM/yyyy", "en-US") + '</strong><br> Ultima modifica del <strong>' + formatDate(coords[i]['BeginDate'], "dd/MM/yyyy", "en-US") + '</strong>');

        data.push(marker);
      }

    }
    return data;
  }


  loading(res) {
    console.log("START LOADING....")
    this.spinner = true;
  }
  loaded(res) {
    let fork = [];
    this.spinner = false;
    console.log("END LOADING....")
    res.data.forEach(element => {

      if (element.DataInizio == null && element.DataEsecuzione != null) {
        //RIFIUTATA
        element.stato = "RIFIUTATA"
        element.tooltip = "La segnalazione risulta non di competenza"
        element.icona = "fas fa-ban"
        element.color = "badge badge-danger"
      } else if (element.DataInizio == null && element.DataEsecuzione == null) {
        //INSERITA
        element.stato = "INSERITA"
        element.tooltip = "La segnalazione risulta inserita"
        element.icona = "fas fa-folder"
        element.color = "badge badge-secondary"
      } else if (element.DataInizio != null && element.DataEsecuzione == null) {
        //LAVORAZIONE
        element.stato = "LAVORAZIONE"
        element.tooltip = "La segnalazione risulta in lavorazione"
        element.icona = "fas fa-wrench"
        element.color = "badge badge-warning"
      } else if (element.DataInizio != null && element.DataEsecuzione != null) {
        //CONCLUSA
        element.stato = "CONCLUSA"
        element.tooltip = "La segnalazione risulta conclusa con successo"
        element.icona = "fas fa-check"
        element.color = "badge badge-success"
      }
    });
    //"fas fa-thumbs-up" : "fas fa-ban"
    this.segnalazioniFiltered = res.data;
    this.segnalazioniOriginali = res.data;
    this.sourceLoaded = true;
    this.setMapPoint()

  }



  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction !== '' && column !== '') {
      this.segnalazioniFiltered = [...this.segnalazioniFiltered].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }



  groupByFn(item) {
    return item.Gruppo
  }

  setDetail(seg?: any) {
    this.viewDetail = false;
    this.detail = seg;
    this.mapPoint = {
      name: this.detail.Indirizzo,
      latitude: this.detail.latitude,
      longitude: this.detail.longitude,

    }
    this.viewDetail = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.mappa.refresh();
    }, 100);

  }



  filtraggio() {
    this.segnalazioniFiltered = this.segnalazioniOriginali;
    if (this.ambito?.Id) {
      this.segnalazioniFiltered = this.segnalazioniFiltered.filter(x => x._Ambito_Code == this.ambito.Code);
    }
    if (this.testo) {
      console.log(this.testo)
      this.segnalazioniFiltered = this.segnalazioniFiltered.filter(x => x.Description == this.testo);
    }
    if (this.stato) {
      this.segnalazioniFiltered = this.segnalazioniFiltered.filter(x => x.stato == this.stato);
    }
    if (this.startDate) {

      this.segnalazioniFiltered = this.segnalazioniFiltered.filter(x => x.DataInizio > this.startDate);
    }
    if (this.endDate) {

      this.segnalazioniFiltered = this.segnalazioniFiltered.filter(x => x.DataInizio < this.endDate);
    }
  }

  azzera() {
    this.ambito = ""
    this.testo = ""
    this.stato = ""
    this.startDate = ""
    this.endDate = ""
    this.ngOnInit()
  }

}
