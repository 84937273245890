<div class="container">
    <div class="row">
        <div class="col-sm-7">
            <div class="content bg bg--white">
                <ol class="breadcrumb">
                    <li><a href="/home">Home</a> <svg class="icon icon-light"  role = 'img'>
                        <use href="/assets/bootstrap-italia/svg/sprite.svg#it-chevron-right"></use>
                      </svg></li>
                    <li class="active top"> Accessibilità</li>
                    </ol>
                <div class="content__header content__header--left mt-3">
                    <h1 class="content__header__title ">Accessibilità</h1>
                </div>
                <div id="mobile-content-to">
                </div>
                <h2>
                    Linee guida
                </h2>
                Questo sito web è stato progettato e realizzato seguendo le linee guida WCAG 2.1 previste dallo standard
                internazionale WAI del W3C, in coerenza con i requisiti di accessibilità <a
                    href="http://www.w3.org/TR/WCAG21/" target="_blank">WCAG 2.1 - level AA</a>.
                <br>
                Inoltre, sono stati recepiti e applicati i punti di controlLinee guida lo della Verifica Tecnica
                definiti dalla Legge del 9 gennaio 2004 n° 4 “Disposizioni per favorire l’accesso dei soggetti disabili
                agli strumenti informatici” e dal suo recente aggiornamento del Decreto Ministeriale del 20 marzo 2013
                “Allegato A. Regole tecniche disciplinanti l’accessibilità agli strumenti didattici e formativi a favore
                degli alunni disabili”.
                <br>
                I principi che hanno guidato la realizzazione di questo sito web sono stati definiti sulla base di
                quanto indicato dalle linee guida <a href="https://www.w3.org/WAI/" target="_blank">WAI del W3C</a>, tra
                le principali:
                <ul>
                    <li>
                        sono state utilizzate unicamente le tecnologie specificate dalle linee guida del W3C;
                    </li>
                    <li>
                        per ogni contenuto audio visivo è stato fornito un contenuto che, una volta presentato
                        all'utente, svolge essenzialmente la stessa funzione o raggiunge lo stesso scopo del contenuto
                        originario;
                    </li>
                    <li>
                        non si è fatto affidamento unicamente sui colori, in questo modo il testo e la grafica sono
                        comprensibili anche se consultati senza colore;
                    </li>
                    <li>
                        sono stati usati in modo appropriato i marcatori e i fogli di stile;
                    </li>
                    <li>
                        il layout è stato progettato e garantisce l’indipendenza dai vari dispositivi (collegato alla
                        modalità responsive);
                    </li>
                    <li>
                        sono stati progettati e forniti meccanismi di navigazione chiari e consistenti al fine di
                        aumentare la probabilità che una persona possa trovare sul portale ciò che sta cercando;
                    </li>
                    <li>
                        sono state inserite tabelle dotate dei giusti marker affinché i browser ed i vari user agent
                        possano trasformarle a seconda dell’esigenza.
                    </li>
                </ul>
                Tutte le pagine del sito sono state testate hanno superato la validazione HTML5 effettuata con il <a
                    href="https://validator.w3.org/" target="_blank">W3C Validator</a> ed è stata inoltre verificata la
                loro conformità rispetto ai requisiti di accessibilità indicati dalla legge italiana con il servizio <a
                    href="https://chrome.google.com/webstore/detail/arc-toolkit/chdkkkccnlfncngelccgbgfmjebmkmce"
                    target="_blank">ARC Toolkit</a>.
                
                    <h2>Browser</h2>
                Per i browser di riferimento si è tenuto conto dei principali utilizzati dal mercato nell’anno 2016 e di
                quelli che recepissero un punteggio base superiore a 100 al <a href="https://html5test.com/"
                    target="_blank">test ufficiale HTML5</a>, di seguito:
                <ul>
                    <li>
                        Apple Safari versione 5.1.10+
                    </li>
                    <li>
                        Google Chrome versione 20+
                    </li>
                    <li>
                        Microsoft Internet Explorer versione 9+
                    </li>
                    <li>
                        Mozilla Firefox versione 30+
                    </li>
                    <li>
                        Opera versione 15+
                    </li>
                </ul>
                <h2>Dispositivi mobile</h2>
                Questo sito web è progettato per essere compatibile e correttamente consultato e navigato anche
                attraverso i principali tablet e smartphone attualmente disponibili sul mercato.
            </div>